<template>
  <v-card class="ma-0 pa-0 d-block">
    <div>
      <apexchart
        type="bar"
        :options="chartOptions"
        :series="series"
        height="400"
        width="100%"
      ></apexchart>
    </div>
  </v-card>
</template>

<script>
/**
 * ColumnChart
 * @description Apex bar chart
 *
 */

export default {
  name: "ReportsColumnGroupChart",
  props: {
    /**
     * @type {{name: string,type: string,dateLabel: string, title: string,values: {categories: [],series: [],colors: []}}}
     */
    chart: {
      required: true,
      type: Object,
    },
  },
  computed: {
    chartOptions: function () {
      return {
        chart: {
          type: "bar",
          height: "400",
          width: "100%",
          events: {
            mounted: (chart) => {
              chart.windowResizeHandler();
            },
          },
        },
        legend: {
          position: "right",
          horizontalAlign: "right",
          itemMargin: {
            horizontal: 10,
            vertical: 5,
          },
        },

        title: {
          text: this.chart.title,
          align: "center",
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: "22px",
            fontWeight: 800,
            fontFamily: undefined,
            color: "#263238",
          },
        },

        colors: this.chart.values.colors
          ? this.chart.values.colors
          : ["#7e04b7"],
        plotOptions: {
          bar: {
            columnWidth: "100%",
            endingShape: "rounded",
            borderRadius: 6,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          distributed: true,
          textAnchor: "middle",
          formatter: function (value, { seriesIndex, dataPointIndex, w }) {
            return w.config.series[seriesIndex].data[dataPointIndex] + "%";
          },
        },

        stroke: {
          width: 0,
          curve: "straight",
          lineCap: "round",
          colors: ["transparent"],
        },
        yaxis: [
          {
            labels: {
              show: true,
              formatter: function (val) {
                return val + "%";
              },
            },
            title: {
              text: "Percentage",
              style: {
                color: undefined,
                fontSize: "16px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 800,
                cssClass: "apexcharts-yaxis-title",
              },
            },
            max: 100,
          },
        ],
        xaxis: {
          categories: this.chart.values.categories,
          // tickPlacement: "on",
          labels: {
            rotate: 0,
            style: {
              fontSize: "10px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 400,
              cssClass: "apexcharts-xaxis-label",
            },
          },
        },
      };
    },
    series: function () {
      return this.chart.values.series;
    },
  },
};
</script>
