<template>
  <v-card class="ma-0 pa-0 d-block">
    <div>
      <apexchart
        type="bar"
        :options="chartOptions"
        :series="series"
        height="400"
      ></apexchart>
    </div>
  </v-card>
</template>

<script>
/**
 * ColumnChart
 * @description Apex bar chart
 *
 */

export default {
  name: "ReportsColumnDistributedChart",
  props: {
    /**
     * @type {{name: string,type: string,dateLabel: string,title: string,values: {categories: array,series: array,}}}
     */
    chart: {
      required: true,
      type: Object,
    },
  },
  computed: {
    chartOptions: function () {
      return {
        chart: {
          type: "bar",
          height: "400",
        },
        colors: this.chart.values.colors
          ? this.chart.values.colors
          : ["#7e04b7"],
        plotOptions: {
          bar: {
            columnWidth: "85%",
            distributed: false,
            borderRadius: 8,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        stroke: {
          colors: ["transparent"],
          width: 5,
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: "top",
          horizontalAlign: "center",
          itemMargin: {
            horizontal: 10,
            vertical: 5,
          },
        },
        xaxis: {
          categories: this.chart.values.categories,
          tickPlacement: "on",
          labels: {
            rotate: 0,
            style: {
              fontSize: "8px",
            },
          },
        },
        yaxis: [
          {
            labels: {
              show: true,
            },
            title: {
              text: this.chart.values.series[0].name,
              style: {
                fontSize: "16px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 800,
                cssClass: "apexcharts-yaxis-title",
              },
            },
          },
          {
            labels: {
              show: true,
            },
            opposite: true,
            title: {
              text: this.chart.values.series[1].name,
              style: {
                fontSize: "16px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 800,
                cssClass: "apexcharts-yaxis-title",
              },
            },
          },
        ],
      };
    },
    series: function () {
      return this.chart.values.series;
    },
  },
};
</script>
