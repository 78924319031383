<template>
  <div class="p-4 bg-white rounded-lg">
    <ReportCharts
      :mandatory-filter="[
        {
          name: 'date_range',
          title: 'Date range',
          valueFieldName: '',
        },
      ]"
      :urls="urls"
    ></ReportCharts>
  </div>
</template>

<script>
import ReportCharts from "@/own/components/reports/ReportCharts";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
export default {
  name: "ReportMonthlyAverageOrders",
  data: () => ({
    urls: {
      data_url: "/reports/reportOrderReceivingTimeHeatmap",
      show_url: "/reports/reportOrderReceivingTimeHeatmap/show",
    },
  }),
  components: { ReportCharts },
  computed: {},
  beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, true);
  },
};
</script>

<style></style>
